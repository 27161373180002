import { DataProvider, usePlasmicQueryData } from '@plasmicapp/loader-nextjs';

import { LANDING_PAGE_V2_SELECTOR } from '@/graphql/selectors/landingPage';
import { useStore } from '@/models/root-store';
import { formatPriceFields, objectParser } from '@/utils/track/format-helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
  slug: string;
}

export const LandingPageV2DataProvider: React.FC<Props> = ({
  children,
  className,
  slug,
}) => {
  const store = useStore();
  const { data } = usePlasmicQueryData(`/landing/${slug}`, async () => {
    const { allLandingPageV2sCms } = await store.api.queryAllLandingPageV2sCms(
      {
        filter: { slug: { in: [slug, 'shared'] } },
        imgixParams: {
          auto: 'format',
        },
      },
      LANDING_PAGE_V2_SELECTOR,
    );
    return allLandingPageV2sCms;
  });

  if (!data) {
    return null;
  }
  const clonedData = objectParser(data);
  const slugData = clonedData.find((pageData: any) => pageData.slug === slug);
  const sharedData = clonedData.find(
    (pageData: any) => pageData.slug === 'shared',
  );
  formatPriceFields(slugData);
  formatPriceFields(sharedData);
  const formattedData = {
    ...slugData,
    shared: sharedData,
  };
  return (
    <div className={className}>
      <DataProvider name="landingV2" data={formattedData}>
        {children}
      </DataProvider>
    </div>
  );
};
