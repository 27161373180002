import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import Link from 'next/link';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PostcodeEntry from 'src/components/checkDelivery/PostcodeEntry/PostcodeEntry';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';
import type { ProductRestrictedType } from '@/models/api';
import { useStore } from '@/models/root-store';

interface RestrictedDeliveryModalProps {
  productSkus: string[];
  type: ProductRestrictedType;
  modalMessage: string;
  redirectUrl?: string;
  toastMessage?: string;
}

const customContentStyles = {
  width: 'fit-content',
  minWidth: 300,
  maxWidth: 348,
  maxHeight: '90%',
  overflow: 'initial',
};

const RestrictedDeliveryModal = forwardRef<any, RestrictedDeliveryModalProps>(
  ({ productSkus, type, modalMessage, redirectUrl, toastMessage }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const { register } = useForm();

    const { productStore } = useStore();

    useImperativeHandle(ref, () => ({
      showCheckPizzaDeliveryModal: () => {
        setIsModalOpen(true);
      },
    }));

    const handleClick = async (props: any) => {
      const { postcode, name: suburb } = props;
      const postcodeString = postcode.toString();
      productStore.delivery.setDelivery(suburb, postcodeString);
      const result = await productStore.queryValidateRestrictedProducts(
        postcodeString,
        suburb,
        productSkus,
        type,
        true,
      );

      setMessage(result);
      if (!result) {
        toast.success(`${toastMessage}`);
        setIsModalOpen(false);
      }
    };

    const handleClose = () => {
      setMessage(null);
      setIsModalOpen(false);
    };

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        headerTitle=""
        customContentStyles={customContentStyles}
      >
        {message ? (
          <div className="flex flex-col items-center gap-6 px-7 py-8">
            <p
              className="mt-2 text-center"
              dangerouslySetInnerHTML={{ __html: message }}
            />
            <Button
              className="px-6"
              theme="septenary"
              onClick={() => setMessage('')}
            >
              Enter new postcode
            </Button>
            <Link href={redirectUrl || '/terms'} target="_blank">
              <span className="text-attention">Learn more</span>
            </Link>
          </div>
        ) : (
          <div className="flex flex-col items-center px-14 py-8">
            <div className="p-3">
              <Image
                src="/assets/images/svg/delivery_fresh.svg"
                alt="Delivery Fresh"
                width={61}
                height={40}
                className="rounded-l-lg"
              />
            </div>
            <div className="mb-11 mt-4">
              <p className=" text-center font-interBold">{modalMessage}</p>
              <p className="whitespace-pre-line">{message ?? ''}</p>
            </div>
            <div className={'min-h-32 min-w-60'}>
              <PostcodeEntry
                register={register}
                handleClick={handleClick}
                label="Postcode"
                placeholder="Eg. 2000"
              />
            </div>

            <Link href={redirectUrl || '/terms'} target="_blank">
              <span className="text-attention">Learn more</span>
            </Link>
          </div>
        )}
      </Modal>
    );
  },
);

RestrictedDeliveryModal.displayName = 'RestrictedDeliveryModal';

export default observer(RestrictedDeliveryModal);
