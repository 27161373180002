import { observer } from 'mobx-react-lite';
import React from 'react';

import { trackPromotionClicked } from '@/utils/track/tracker.helper';

export type ToggleTheme = 'pizza' | 'default';

export const TOGGLE_THEMES: Record<ToggleTheme, string> = {
  default:
    'h-[14px] w-[34px] after:border-2 after:border-white after:bg-white after:shadow-[0px_1px_1px_0px_#00000029,0px_3px_8px_0px_#00000026] peer-checked:after:bg-primary',
  pizza: `h-[12px] w-[31px] after:bg-[url('https://www.datocms-assets.com/81001/1732071464-pizza.svg')] after:translate-x-[-2px] after:bg-cover after:bg-center after:bg-no-repeat peer-checked:after:rotate-45`,
};

type ToggleProps = {
  creative: string;
  visibility: boolean;
  className?: string;
  theme?: ToggleTheme;
  onChangeVisibility: (visibility: boolean) => void;
};

const Toggle: React.FC<ToggleProps> = observer(
  ({
    creative,
    visibility,
    className,
    theme = 'default',
    onChangeVisibility,
  }: ToggleProps) => {
    const trackToggle = () => {
      trackPromotionClicked(creative, !visibility ? 'on' : 'off');
    };
    const themeClass = TOGGLE_THEMES[theme];

    return (
      <label className={`${className} inline-flex cursor-pointer items-center`}>
        <input
          type="checkbox"
          value=""
          className="peer sr-only"
          onClick={() => {
            onChangeVisibility(visibility);
            trackToggle();
          }}
        />
        <div
          className={`${themeClass} peer relative rounded-full bg-[#A8A8A8] after:absolute after:left-[-3px] after:top-[-3px] after:size-[20px] after:rounded-full after:transition-all after:content-[''] peer-checked:bg-[#222222] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full dark:border-gray-600`}
        ></div>
      </label>
    );
  },
);

export default Toggle;
