import React, { useRef } from 'react';

import Button from '@/components/common/Button/Button';
import RestrictedDeliveryModal from '@/components/common/RestrictedDeliveryModal/RestrictedDeliveryModal';
import { ProductRestrictedType } from '@/models/api';

interface CheckRestrictedDeliveryButtonProps {
  children: React.ReactNode;
  className?: string;
  modalMessage: string;
  productSkus: { sku: string }[];
  redirectUrl?: string;
  toastMessage?: string;
  theme?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'custom'
    | 'wlp'
    | 'calorie'
    | 'muscle'
    | 'tofu'
    | 'quaternary'
    | 'mealPlan';
}

export const CheckRestrictedDeliveryButton: React.FC<
  CheckRestrictedDeliveryButtonProps
> = ({
  children,
  theme,
  className,
  productSkus,
  modalMessage,
  redirectUrl,
  toastMessage,
}) => {
  const checkDeliveryRef = useRef<any | null>(null);
  const skuList = productSkus.map((product) => product.sku);
  return (
    <div className={className}>
      <Button
        theme={theme}
        onClick={() => checkDeliveryRef.current?.showCheckPizzaDeliveryModal()}
      >
        {children}
      </Button>
      <RestrictedDeliveryModal
        modalMessage={modalMessage}
        productSkus={skuList}
        type={ProductRestrictedType.PRODUCT}
        ref={checkDeliveryRef}
        redirectUrl={redirectUrl}
        toastMessage={toastMessage}
      />
    </div>
  );
};
