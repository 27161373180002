import { DataProvider, usePlasmicQueryData } from '@plasmicapp/loader-nextjs';

import { LANDING_PAGE_V2_SELECTOR } from '@/graphql/selectors/landingPage';
import { useStore } from '@/models/root-store';
import { getLandingPageVariation } from '@/utils/api-helpers';
import { formatPriceFields, objectParser } from '@/utils/track/format-helpers';

interface Props {
  children: React.ReactNode;
  className?: string;
  slug: string;
  content: string;
  campaign: string;
}

export const DyanmicLandingPageDataProvider: React.FC<Props> = ({
  children,
  className,
  content = 'default',
  campaign,
  slug,
}) => {
  const store = useStore();
  const { data: landingPageContent } = usePlasmicQueryData(
    `dynamicCampaign/${campaign}/${slug}/${content}`,
    async () => {
      return getLandingPageVariation(store, campaign, slug, content);
    },
  );
  const landingPageId = landingPageContent?.landingPage?.id;
  const { data: landingPageData } = usePlasmicQueryData(
    `/dynamicLanding/${landingPageId}`,
    async () => {
      if (!landingPageId) {
        return null;
      }
      const { allLandingPageV2sCms } =
        await store.api.queryAllLandingPageV2sCms(
          {
            filter: {
              OR: [{ id: { eq: landingPageId } }, { slug: { eq: 'shared' } }],
            },
            imgixParams: {
              auto: 'format',
            },
          },
          LANDING_PAGE_V2_SELECTOR,
        );
      return allLandingPageV2sCms;
    },
  );

  if (!landingPageData) {
    return null;
  }
  const clonedData = objectParser(landingPageData);
  const slugData = clonedData.find((pageData: any) => pageData.slug === slug);
  const sharedData = clonedData.find(
    (pageData: any) => pageData.slug === 'shared',
  );
  formatPriceFields(slugData);
  formatPriceFields(sharedData);
  const formattedData = {
    ...slugData,
    shared: sharedData,
  };
  return (
    <div className={className}>
      <DataProvider name="dynamicLanding" data={formattedData}>
        {children}
      </DataProvider>
    </div>
  );
};
